interface Result {
  type: string;
}

interface AuthenticationResult extends Result {
  authorization_code: string;
  code_verifier: string;
}

function SendMessage(message: any) {
  try {
    //Iframe
    if (window.parent) {
      window.parent.postMessage(message, "*");
    }

    //Cordova
    if (window.webkit !== undefined) {
      window.webkit.messageHandlers.cordova_iab.postMessage(
        JSON.stringify(message)
      );
    }
  } catch (e) {
    console.log(e);
  }

  return;
}

export default function SendAuthorizationCodeMessage(
  message: AuthenticationResult
) {
  SendMessage(message);
  console.log("authorization_code", message.authorization_code);
  console.log("code_verifier", message.code_verifier);
}

export function CloseMessage() {
  SendMessage({
    type: "close",
  });
}

export function ScrollToTopMessage() {
  SendMessage({
    type: "scroll_to_top",
  });
}

export function SendUserSessionMessage(userSessionId: string) {
  SendMessage({
    type: "user_session",
    user_session_id: userSessionId,
  });
}
